import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [0];

export const dictionary = {
		"/[[lang=lang]]/(app)": [10,[2,3]],
		"/[[lang=lang]]/access-denied": [41,[2]],
		"/[[lang=lang]]/(app)/admin/email-templates": [~13,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/local-authorities": [~14,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/machine-users": [15,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/machine-users/new": [~17,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/machine-users/[id=machineUserID]": [16,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/service-announcements": [18,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/user-registration": [~19,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/users": [20,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/users/invited": [24,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/users/invite": [~23,[2,3,4]],
		"/[[lang=lang]]/(app)/admin/users/[id]": [21,[2,3,4,5]],
		"/[[lang=lang]]/(app)/admin/users/[id]/roles": [22,[2,3,4,5]],
		"/[[lang=lang]]/cookies": [42,[2]],
		"/[[lang=lang]]/(app)/(help-area)/faq": [~11,[2,3]],
		"/[[lang=lang]]/(app)/form/(group)/document-request": [25,[2,3]],
		"/[[lang=lang]]/(app)/form/(group)/feedback": [26,[2,3]],
		"/[[lang=lang]]/(app)/form/group": [30,[2,3]],
		"/[[lang=lang]]/(app)/form/(group)/myepay": [27,[2,3]],
		"/[[lang=lang]]/(app)/form/(group)/payroll": [28,[2,3]],
		"/[[lang=lang]]/(app)/form/[formKind=formKind]": [~29,[2,3]],
		"/[[lang=lang]]/(app)/(help-area)/help": [~12,[2,3]],
		"/[[lang=lang]]/(app)/investigations/[id]": [31,[2,3,6]],
		"/[[lang=lang]]/(app)/investigations/[id]/[submissionID]/raw": [32,[2,3,6,7]],
		"/[[lang=lang]]/(app)/investigations/[id]/[submissionID]/summary": [33,[2,3,6,7]],
		"/[[lang=lang]]/invite/[id]": [43,[2]],
		"/[[lang=lang]]/login": [~44,[2]],
		"/[[lang=lang]]/policies/[policy=policy]": [~45,[2]],
		"/[[lang=lang]]/register-interest": [~46,[2]],
		"/[[lang=lang]]/(app)/submissions": [34,[2,3]],
		"/[[lang=lang]]/(app)/submissions/all": [39,[2,3]],
		"/[[lang=lang]]/(app)/submissions/(assigned)/assigned": [37,[2,3,8]],
		"/[[lang=lang]]/(app)/submissions/[form=formKind]": [38,[2,3]],
		"/[[lang=lang]]/(app)/submissions/(assigned)/[id=submissionID]": [35,[2,3,8,9]],
		"/[[lang=lang]]/(app)/submissions/(assigned)/[id=submissionID]/convert": [~36,[2,3,8,9]],
		"/[[lang=lang]]/(app)/submit-as": [~40,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';